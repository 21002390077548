import loadable from '@loadable/component';
import React from 'react';

import { MainAnimation } from '@components/Home';

const HeroStandard = loadable(() => import('./HeroStandard'))
const HeroFullWidth = loadable(() => import('./HeroFullWidth'));
const HeroSplitFullWidth = loadable(() => import('./HeroSplitFullWidth'));
const HeroVideoLoop = loadable(() => import('./HeroVideoLoop'));
const HeroBlogListing = loadable(() => import('./HeroBlogListing'));
const HeroImageFloat = loadable(() => import('./HeroImageFloat'));
const HeroUsecase = loadable(() => import('./HeroUsecase'));

export const Hero = ({ component, seoBreadcrumbs }) => {
  const {
    type
  } = component
  switch (type) {
    case "standard":
      return <HeroStandard component={component} seoBreadcrumbs={seoBreadcrumbs} />;
    case "standard-tall":
      return <HeroStandard component={component} seoBreadcrumbs={seoBreadcrumbs} />;
    case "homepage-main":
      return <MainAnimation component={component} seoBreadcrumbs={seoBreadcrumbs} />;
    case "grey-full-width-center":
      return <HeroFullWidth component={component} seoBreadcrumbs={seoBreadcrumbs} />;
    case "grey-full-width-left":
      return <HeroFullWidth component={component} seoBreadcrumbs={seoBreadcrumbs} />;
    case "grey-dark-gradient-left":
      return <HeroFullWidth component={component} seoBreadcrumbs={seoBreadcrumbs} />;
    case "grey-dark-gradient-center":
      return <HeroFullWidth component={component} seoBreadcrumbs={seoBreadcrumbs} />;
    case "blue-full-width":
      return <HeroFullWidth component={component} seoBreadcrumbs={seoBreadcrumbs} />;
    case "blue-split-full-width":
      return <HeroSplitFullWidth component={component} seoBreadcrumbs={seoBreadcrumbs} />;
    case "blue-split-full-width-tall":
      return <HeroSplitFullWidth component={component} seoBreadcrumbs={seoBreadcrumbs} />;
    case "video-loop":
      return <HeroVideoLoop component={component} seoBreadcrumbs={seoBreadcrumbs} />;
    case "blog-listing":
      return <HeroBlogListing component={component} seoBreadcrumbs={seoBreadcrumbs} />;
    case "main-image-float":
      return <HeroImageFloat component={component} seoBreadcrumbs={seoBreadcrumbs} />;
    case "usecase":
      return <HeroUsecase component={component} seoBreadcrumbs={seoBreadcrumbs} />;
    default:
      return <HeroStandard component={component} seoBreadcrumbs={seoBreadcrumbs} />;
  }
}