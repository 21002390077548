import loadable from "@loadable/component"
import React from "react"

import { Hero } from "@components/Hero"
import Heading from "../components/Heading"

const CardDeck = loadable(() => import("../components/CardDeck/CardDeck"))
const Carousel = loadable(() => import("../components/Carousel/Carousel"))
const ComparisonTables = loadable(() =>
  import("../components/ComparisonTables/ComparisonTables")
)
const PricingTable = loadable(() =>
  import("../components/PricingTable/PricingTable")
)
const ConversionPanel = loadable(() =>
  import("../components/ConversionPanel/ConversionPanel")
)
const FAQAccordion = loadable(() =>
  import("../components/Accordions/FAQAccordion")
)
const IconBlock = loadable(() => import("../components/IconBlock/IconBlock"))
const MktoForm = loadable(() => import("../components/Form/MktoForm"))
const Slidebox = loadable(() => import("../components/Slidebox"))
const Switchback = loadable(() => import("../components/Switchback/Switchback"))
const TestimonialSection = loadable(() =>
  import("../components/TestimonialSection/TestimonialSection")
)
const TileSection = loadable(() => import("../components/TileSection"))
const Trustbar = loadable(() => import("../components/Trustbar/Trustbar"))
const VideoSection = loadable(() =>
  import("../components/VideoSection/VideoSection")
)
const UseCase = loadable(() => import("../components/UseCase/UseCase"))

export default function componentGenerator(slug, components, seoBreadcrumbs) {
  return (
    <>
      {components &&
        components.map((component, index) => {
          switch (component?.__typename) {
            case "ContentfulComponentConversionPanel":
              return (
                <ConversionPanel
                  component={component}
                  key={"component" + index}
                  slug={slug}
                />
              )
            case "ContentfulComponentHeading":
              return (
                <Heading
                  component={component}
                  key={"component" + index}
                  slug={slug}
                />
              )
            case "ContentfulComponentHero":
              return (
                <Hero
                  component={component}
                  key={"component" + index}
                  slug={slug}
                  seoBreadcrumbs={seoBreadcrumbs}
                />
              )
            case "ContentfulComponentSwitchback":
              return (
                <Switchback
                  component={component}
                  key={"component" + index}
                  slug={slug}
                />
              )
            case "ContentfulComponentCarousel":
              return (
                <Carousel
                  component={component}
                  key={"component" + index}
                  slug={slug}
                />
              )
            case "ContentfulComponentTrustbar":
              return (
                <Trustbar
                  component={component}
                  key={"component" + index}
                  slug={slug}
                />
              )
            case "ContentfulComponentFaqAccordion":
              return (
                <FAQAccordion
                  component={component}
                  key={"component" + index}
                  slug={slug}
                />
              )
            case "ContentfulComponentForm":
              return (
                <MktoForm
                  component={component}
                  key={"component" + index}
                  slug={slug}
                />
              )
            case "ContentfulComponentIconBlock":
              return (
                <IconBlock
                  component={component}
                  key={"component" + index}
                  slug={slug}
                />
              )
            case "ContentfulComponentTestimonialSection":
              return (
                <TestimonialSection
                  component={component}
                  key={"component" + index}
                  slug={slug}
                />
              )
            case "ContentfulComponentVideoSection":
              return (
                <VideoSection
                  component={component}
                  key={"component" + index}
                  slug={slug}
                />
              )
            case "ContentfulComponentCardDeck":
              return (
                <CardDeck
                  component={component}
                  key={"component" + index}
                  slug={slug}
                />
              )
            case "ContentfulComponentTileSection":
              return (
                <TileSection
                  component={component}
                  key={"component" + index}
                  slug={slug}
                />
              )
            case "ContentfulComponentComparisonTables":
              return (
                <ComparisonTables
                  component={component}
                  key={"component" + index}
                  slug={slug}
                />
              )
            case "ContentfulComponentPricingTable":
              return (
                <PricingTable
                  component={component}
                  key={"component" + index}
                  slug={slug}
                />
              )
            case "ContentfulComponentUseCase":
              return (
                <UseCase
                  component={component}
                  key={"component" + index}
                  slug={slug}
                />
              )
            case "ContentfulComponentSlidebox":
              return (
                <Slidebox
                  component={component}
                  key={"component" + index}
                  slug={slug}
                />
              )
            default:
              return null
          }
        })}
    </>
  )
}
