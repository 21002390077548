import styled from 'styled-components';

export const HomeHeroWrapper = styled.div`
  background-size: cover;
  background-position: center;
  padding: 200px 60px 370px;
  font-family: 'Roboto', sans-serif;
  position: relative;
  margin-bottom: 50px;
  
  @media (min-width: 675px) {
    padding-bottom: 420px;
  }

  @media (min-width: 840px) {
    padding-bottom: 460px;
  }

  @media (min-width: 900px) {
    padding-bottom: 480px;
  }

  @media (min-width: 915px) {
    padding-bottom: 520px;
  }

  &:before {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background: linear-gradient(180deg, #090069 23.61%, rgba(9, 0, 105, 0.2) 98.49%);
  }
  .background-image {
    position: absolute;
    width: 100vw;
    z-index: -1;
    height: 100%;
    top: 0;
    left: 0;
  }
  .home-hero--copy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 100;
    .home-hero--heading {
      font-weight: 900;
      font-size: 68px;
      line-height: 72px;
      text-align: center;
      color: #FFFFFF;
      @media (max-width: 768px) {
        font-size: 44px;
        line-height: 48px;
      }
      @media (max-width: 576px) {
        font-size: 38px;
        line-height: 44px;
      }
    }
    .home-hero--description {
      font-size: 22px;
      line-height: 30px;
      text-align: center;
      color: #E4E7EC;
      margin: 16px 0 0;
      max-width: 770px;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 28px;
      }
      @media (max-width: 576px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .home-hero--buttons {
      margin: 32px 0 0;
      display: flex;
      align-items: center;
      @media (max-width: 576px) {
        flex-direction: column;
      }
      .home-hero--button-play {
        background: transparent;
        color: white;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration-line: underline;
        color: #FFFFFF;
        outline: none;
        border: none;
        height: 54px;
        width: 147px;
        margin-left: 16px;
        cursor: pointer;
        transition: opacity 0.2s ease-in;
        img {
          width: 38px;
          height: 38px;
          margin-right: 10px;
        }
        &:hover {
          opacity: 0.7;
        }
        &:focus-visible {
          opacity: 0.7;
          border: 1px solid orange;
          border-radius: 40px;
        }
        @media (max-width: 576px) {
          margin: 16px 0 0;
        }
      }
    }
  }

  .home-hero--featured-image-container {
    width: 100%;

    .home-hero--anim-wrapper {
      position: absolute;
      width: 100%;
      left: 0;
      padding-top: 50px;

      .home-hero--lottie-wrapper {
        max-width: 900px;
        width: 90%;
        height: auto;
        position: relative;
        z-index: 101;
        margin: 0 auto;

        .home-hero--anim-overlay {
          position: absolute;
          left: 30px;
          width: calc(100% - 60px);
          top: 55px;
          height: calc(100% - 118px);
          background: linear-gradient(126.6deg, rgba(255, 255, 255, 0.12) 28.69%, rgba(255, 255, 255, 0) 100%);
          backdrop-filter: blur(86px);
          border-radius: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 50px 70px;

          @media (max-width: 768px) {
            left: 3%;
            width: 94%;
            top: 10%;
            height: 80%;
          }
    
          &:before {
            content: ' ';
            position: absolute;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            border: 2px solid #FFF;
            mask-image: linear-gradient(45deg ,transparent 45%, black 100%);
            opacity: 60%;
            border-radius: 26px;
          }
        }
        .home-hero--iframe-wrapper {
          position: relative;
          padding-bottom: 56.25%;
          height: 0;
          overflow: hidden;


          .home-hero--iframe {
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            border: none;
          }
        }

      }
    }

    .home-hero--video-wrapper {
      position: absolute;
      bottom: 30px;
      left: 0;
      width: 100%;
      height: 350px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .home-hero--marquee {
        opacity: 0.8;

        img {
          height: 70px;
          width: auto;
          margin: 0 20px;
        }
      }

    }
  }
`
