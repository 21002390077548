import React from "react"
import Marquee from "react-fast-marquee"
import { Button, Heading } from "@atoms"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { HomeHeroWrapper } from "./styles/MainAnimation.styled"
import OptimizedImage from "../../utils/optimizedImage"

const HomeHeroCTA = ({ callsToAction }) => {
  return (
    <div className="home-hero--buttons">
      {callsToAction &&
        callsToAction.map((cta, idx) => {
          if (cta?.type !== "triggers-video-modal") {
            return (
              <Button key={idx} ctaVariant="primary" ctaLink={cta?.link}>
                {cta?.label}
              </Button>
            )
          }
        })}
    </div>
  )
}

export const MainAnimation = ({ component }) => {
  const {
    callsToAction,
    background,
    featuredImage,
    heading,
    headingSize,
    subheading,
  } = component

  const getYoutubeId = url => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    const match = url?.match(regExp)
    return match && match[7].length === 11 ? match[7] : false
  }

  const youtubeVideo = callsToAction.find(
    cta => cta.type === "triggers-video-modal"
  )
  const videoId = youtubeVideo?.link ? getYoutubeId(youtubeVideo?.link) : ""

  const companies = [
    "adp",
    "attlasian",
    "bmc",
    "cornerstone",
    "emburse-chromeriver",
    "igloo",
    "jira",
    "office",
    "oracle",
    "paychex",
    "sage",
    "salesforce",
    "sap-ariba",
    "sap-concur",
    "sap-fieldglass",
    "sap",
    "scim",
    "service-now",
    "taleo",
    "ukg",
    "workday",
    "zendesk",
  ]

  const shuffledCompanies = companies.sort(() => 0.5 - Math.random())

  const renderMarquee = props => (
    <div className="home-hero--marquee">
      <Marquee gradient={false} {...props}>
        {shuffledCompanies.map(name => (
          <img
            key={name}
            src={`/images/logos/${name}.png`}
            loading="eager"
            alt={`${name} logo`}
          />
        ))}
      </Marquee>
    </div>
  )

  return (
    <div data-comp={MainAnimation.displayName}>
      <HomeHeroWrapper className="home-hero--wrapper home-hero--main-animation-wrapper">
        <OptimizedImage
          className="background-image"
          loading="eager"
          image={featuredImage?.gatsbyImageData}
          src={featuredImage?.file?.url}
          alt=""
        />
        <div className="home-hero--copy-container">
          {heading && (
            <Heading
              background={background}
              headingSize={headingSize}
              className="home-hero--heading"
              style={{ textAlign: "center" }}
            >
              {heading}
            </Heading>
          )}
          {subheading?.raw && (
            <p className="home-hero--description">
              {documentToReactComponents(JSON.parse(component.subheading?.raw))}
            </p>
          )}
          {callsToAction && <HomeHeroCTA callsToAction={callsToAction} />}
        </div>
        <div className="home-hero--featured-image-container">
          <div className="home-hero--anim-wrapper">
            <div className="home-hero--lottie-wrapper">
              <div className="home-hero--anim-overlay" />
              <div className="home-hero--iframe-wrapper">
                <iframe
                  width="853"
                  height="480"
                  src={`https://www.youtube.com/embed/${videoId}?rel=0`}
                  allow="autoplay;encrypted-media;"
                  allowFullScreen
                  className="home-hero--iframe"
                  frameBorder="0"
                />
              </div>
            </div>
          </div>
          <div className="home-hero--video-wrapper">
            {renderMarquee({ speed: 10, direction: "right" })}
            {renderMarquee({ speed: 15 })}
            {renderMarquee({ speed: 12, direction: "right" })}
          </div>
        </div>
      </HomeHeroWrapper>
    </div>
  )
}

MainAnimation.displayName = "MainAnimation"
