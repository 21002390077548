import styled, { css } from "styled-components"
import { color } from "../../../atoms/colors"

export const HeadingWrapper = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  // Grey 100 or White
  background-color: ${({ bgColor }) => (bgColor === "grey" ? color.grey[100] : color.common.white)};
  padding: 96px 30px 0;
  @media (max-width: 992px) {
    padding: 64px 30px 0;
  }
  @media (max-width: 576px) {
    padding: 48px 15px 0;
  }
  ${({ noTopPadding }) => css`
    padding-top: ${noTopPadding === true && 0};
    @media (max-width: 992px) {
      padding-top: ${noTopPadding === true && 0};
    }
    @media (max-width: 768px) {
      padding-top: ${noTopPadding === true && 0};
    }
  `}
`

export const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 770px;
  @media (max-width: 576px) {
    max-width: 506px;
  }
`

export const Kicker = styled.p`
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  // Workgrid Blue
  color: #002DC2;
  margin-bottom: 24px;
`

export const HeadingText = styled.h2`
  text-align: center;
  // Midnight Black
  color: #1B1B1B;
  font-weight: 900;
  margin-bottom: 16px;
  // Display L
  &.heading-2 {
    font-size: 50px;
    line-height: 60px;
    @media (max-width: 992px) {
      font-size: 38px;
      line-height: 48px;
    }
    @media (max-width: 576px) {
      font-size: 32px;
      line-height: 42px;
    }
  }
  // Display M
  &.heading-3 {
    font-weight: 700;
    font-size: 38px;
    line-height: 48px;
    @media (max-width: 992px) {
      font-size: 32px;
      line-height: 42px;
    }
    @media (max-width: 576px) {
      font-size: 28px;
      line-height: 34px;
    }
  }
`

export const Subheading = styled.p`
  // Grey 600
  color: #475467;
  // Text L
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const CtaWrapper = styled.div`
  margin: 32px auto 0;
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 24px;
  grid-auto-flow: column;
  width: fit-content;
  @media (max-width: 576px) {
    grid-auto-flow: row;
    width: 100%;
    button {
      width: 100%;
    }
  }
`
